<script setup lang="ts">
interface Props {
  title?: string
  desc?: string
  btnTxt?: string
  showBtn?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['emptyEvent'])
const { t } = useI18n()

const defaultProps = computed<Required<Props>>(() => {
  return {
    title: props.title || t('key.empty.title'),
    desc: props.desc ?? t('key.empty.desc'),
    btnTxt: props.btnTxt ?? t('key.common.button.refresh'),
    showBtn: props.showBtn || true,
  }
})

function clickBtn() {
  emit('emptyEvent')
}
</script>

<template>
  <div class="empty-page">
    <img src="~/assets/images/bg_empty.png" class="bg w-35 md:w-40" />
    <p v-if="defaultProps.title" class="mt-4" text="base md:xl black" font="500">
      {{ defaultProps.title }}
    </p>
    <p v-if="defaultProps.desc" class="desc">
      {{ defaultProps.desc }}
    </p>
    <div v-if="defaultProps.btnTxt && defaultProps.showBtn" class="btn" @click.stop="clickBtn">
      {{ defaultProps.btnTxt }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.empty-page {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 32px 40px;

  .title {
    font-size: 16px;
    color: #121212;
    font-weight: Medium;
  }

  .desc {
    margin-top: 8px;
    color: #666666;
    font-weight: 300;
    font-size: 13px;
    font-weight: Light;
    line-height: 20px;

    text-align: center;
  }

  .btn {
    margin-top: 16px;

    padding: 8px 20px;
    border-radius: 4px;
    background: #121212;

    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
